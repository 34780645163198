export const methodIcons = [
  { id: 1, name: 'ri-wallet-2-line' },
  { id: 2, name: 'ri-bank-card-fill' },
  { id: 3, name: 'ri-wallet-3-fill' },
  { id: 4, name: 'ri-bank-card-2-fill' },
  { id: 5, name: 'ri-refund-2-line' },
  { id: 6, name: 'ri-money-dollar-box-fill' },
  { id: 7, name: 'ri-bit-coin-fill' },
  { id: 8, name: 'ri-coins-fill' },
  { id: 9, name: 'ri-visa-line' },
  { id: 10, name: 'ri-file-transfer-fill' },
  { id: 11, name: 'ri-refund-2-fill' },
  { id: 12, name: 'ri-secure-payment-fill' },
  { id: 13, name: 'ri-exchange-dollar-fill' },
  { id: 14, name: 'ri-coin-fill' },
  { id: 15, name: 'ri-currency-fill' },

]

export const countries = [
  {
    id: 1,
    name: '+57',
    code: '57',
    avatar: { src: 'https://i.postimg.cc/qqV10wPg/colombia.png' },
  },
  {
    id: 2,
    name: '+502',
    code: '502',
    avatar: { src: 'https://i.postimg.cc/6QHXWy57/guatemala.png' },
  },
]

export const times = [
  { name: 'Minutos', id: 0 },
  { name: 'Horas', id: 1 },
  { name: 'Días', id: 2 },
]

export const apiKey = 'AIzaSyDruBte_04SPBgxP5zNhTaO7uk6S5XtDLs'
export const regexEmail = /^[\w.%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i
export const MSG_CODE = '|*+change+*|'
export const MSG_REPLACE = '¿Hay un cambio de precios en tu orden, deseas confirmar el nuevo total?'

export const COMMISION_CONCEPT = 'Commission'
export const DOMICILE_CONCEPT = 'Domicile'
